import React from 'react'

import * as styles from './styles.module.scss'

const Policy = () => (
  <div className={styles.privacyBlock}>
    <h2>Privacy policy</h2>
    <p className={styles.privacyBlockText}>Your privacy is important to us.</p>
    <p className={styles.privacyBlockText}>
      Justice IT has created this Privacy Policy in line with Justice IT's
      commitment to your privacy on Justice IT websites. The following discloses
      Justice IT's information gathering, dissemination and protection
      practices.
    </p>
    <p className={styles.privacyBlockText}>
      By getting access to the website or using it, you agree to the terms of
      the Privacy Policy of this website. This document applies to all
      information that you provide to us, including but not limited to contact
      form, questions, feedback, comments and suggestions, collection and use of
      personal information that you can provide to us as a result of your
      activities on this website.
    </p>
    <p className={styles.privacyBlockText}>
      You can opt out of our data requests or not accept our Policy, but you
      should understand that in this case, Justice IT may not be able to provide
      certain services and you can not use our site.
    </p>
    <p className={styles.privacyBlockText}>
      Justice IT reserves the right to modify or amend this Privacy Policy at
      its own discretion from time to time. The changes can be tracked on this
      website. Your subsequent use of Justice IT website constitutes your
      acceptance of those changes.
    </p>
    <p className={styles.privacyBlockSubTitle}>PERSONAL DATA LIST</p>
    <p className={styles.privacyBlockText}>
      Personal data is any information related to an identified or identifiable
      individual (data-subject), with the help of which the individual can be
      determined directly or indirectly. Such information includes, inter alia,
      name, location, online identifier or one or more factors specific to the
      physical, physiological, genetic, intellectual, economic, cultural or
      social identity of the individual (Clause 1, Article 4).
    </p>
    <p className={styles.privacyBlockText}>Justice IT collects information:</p>
    <p className={styles.privacyBlockText}>
      <ul>
        <li>
          information you affirmatively choose to submit to us (such as your
          name, email address, company name, title, country, telephone) for the
          purpose of accessing various downloads, viewing specific content,
          subscribing to Justice IT newsletters and your preferences in
          receiving marketing from Justice IT and its third-party partners and
          your communication preferences, etc.;
        </li>
        <li>
          information Justice IT receives from your use of Justice IT websites,
          such as the IP address, browser type, ISP, referring/exit pages,
          platform type, date/time stamp, number of clicks, domain name and
          country/state of all visitors to Justice IT's websites;
        </li>
        <li>
          location information when you use Justice IT's websites as Justice IT
          may collect and process information about your location. Justice IT
          uses «google analytics» to determine location;
        </li>
        <li>
          information that is considered as aggregated data such as statistical
          or demographic data which Justice IT may use for any purpose.
          Aggregated data may be derived from your personal information but is
          not considered personal information in law as it does not directly or
          indirectly reveal your identity, unless Justice IT combines or
          connects aggregated data with your personal information so that it can
          directly or indirectly identify you;
        </li>
        <li>
          Justice IT collects technical data from various third parties and
          public sources as, such as: analytics providers, advertising networks,
          search information providers etc.
        </li>
      </ul>
      <p className={styles.privacyBlockText}>
        Other than as required by law, Justice IT websites do not collect any
        special categories of personal information about you (this includes
        details about your race or ethnicity, religious or philosophical
        beliefs, sex life, sexual orientation, political opinions, trade union
        membership, information about your health and genetic and biometric
        data). Justice IT will not collect any information about criminal
        convictions and offenses.
      </p>
      <p className={styles.privacyBlockSubTitle}>
        PURPOSE OF PERSONAL DATA COLLECTION
      </p>
      <p className={styles.privacyBlockText}>
        Justice IT collects information to provide better services to its users
        and to understand better the visitors to its websites and what content
        is of interest to them.
      </p>
      <p className={styles.privacyBlockText}>
        Justice IT will only use your personal information for the purposes for
        which it was collected, unless it reasonably considers that it is needed
        to be used for another reason that is compatible with the original
        purpose. If Justice IT needs to use your personal information for an
        unrelated purpose, Justice IT will notify you, ask permission and
        explain the legal basis which allows Justice IT to do so.
      </p>
      <p className={styles.privacyBlockSubTitle}>USE OF INFORMATION</p>
      <p className={styles.privacyBlockText}>
        Any information collected by Justice IT will be used in accordance with
        this Privacy Policy for the purposes of making better business
        decisions, supporting the activities you choose to engage in, and
        providing higher quality solutions and services to Justice IT's
        customers. Justice IT uses your location information to tailor your
        experience with Justice IT websites. It may also use any non-personal
        information collected to enhance Justice IT website and your IP address
        for website diagnostics and administration. Justice IT will only retain
        your personal information for as long as necessary to fulfill the
        purposes it was collected for, including for the purposes of satisfying
        any legal, accounting, or reporting requirements, but, no more than 1
        (one) year from the date of filling in the form on the Justice IT
        website.
      </p>
      <p className={styles.privacyBlockText}>
        Justice IT processes information on servers in many countries around the
        world and you consent to your personal information being processed on a
        server located outside the country in which you reside. These countries
        may have data protection laws that are different to the laws of your
        country (and, in some cases, may not be as protective). However, Justice
        IT has taken appropriate precautions to require that your personal
        information will remain protected in accordance with this Privacy
        Policy, including the use of the current European legislation on data
        protection (including GDPR), which requires all companies to protect
        personal information processed from the European Economic Area.
      </p>
      <p className={styles.privacyBlockSubTitle}>INFORMATION SECURITY</p>
      <p className={styles.privacyBlockText}>
        We have put in place appropriate security measures to prevent your
        personal information from being accidentally lost, used or accessed in
        an unauthorized way, altered or disclosed. We have put in place
        procedures to deal with any suspected data security breach and will
        notify you and any applicable regulator of a suspected breach where we
        are legally required to do so.
      </p>
      <p className={styles.privacyBlockSubTitle}>LINKS</p>
      <p className={styles.privacyBlockText}>
        Justice IT website can contain links, plug-ins, and applications to
        various third-party websites. Clicking on those links or enabling those
        connections may allow third parties to collect or share data about you.
        Justice IT has no control over and is not responsible for the content,
        privacy policies or reliability of such third party connections. When
        you leave Justice IT's website, Justice IT encourages you to read the
        privacy notice and privacy policy of every website you visit.
      </p>
      <p className={styles.privacyBlockSubTitle}>YOUR DATA PROTECTION RIGHTS</p>
      <p className={styles.privacyBlockText}>
        You have the right to request confirmation of the fact of processing
        your data, the place and purpose of the processing, the categories of
        personal data being processed, information about third parties to which
        your personal data is disclosed, the period during which the data will
        be processed, and the source of receipt of personal data;
      </p>
      <p className={styles.privacyBlockText}>
        If you wish to raise a complaint about how we have handled your personal
        data, you can contact our Data Protection Officer who will investigate
        the matter. If you are not satisfied with our response or believe we are
        processing your personal data not in accordance with the law you can
        complain to the Personal Data Protection Organization.
      </p>
      <p className={styles.privacyBlockText}>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access the information (or to
        exercise any of your other rights). This is another appropriate security
        measure to ensure that personal information is not disclosed to any
        person who has no right to receive it. You will not have to pay a fee to
        access your personal information (or to exercise any of the other
        rights). However, Justice IT may charge a reasonable fee or refuse to
        comply with your request if your request is clearly unfounded,
        repetitive or excessive. Justice IT tries to respond to all legitimate
        requests within 72 hours of receipt. Occasionally, it may take longer if
        your request is particularly complex or you have made a number of
        requests. In this case, Justice IT will notify you and keep you updated.
      </p>
      <p className={styles.privacyBlockSubTitle}>CONTACT INFORMATION</p>
      <p className={styles.privacyBlockText}>
        It is important that the personal information Justice IT holds about you
        is accurate and current. Please keep Justice IT informed if your
        personal information changes during your relationship with Justice IT.
        Additionally, if you have any questions about this Privacy Policy, the
        practices of Justice IT website or your interaction with Justice IT via
        a website, please contact{' '}
        <a href="mailto:hello@it-justice.com">hello@it-justice.com</a>.
      </p>
    </p>
  </div>
)

export default React.memo(Policy)
